var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{attrs:{"offset-y":"","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"actionButton",attrs:{"data-type":"actionButton","icon":"","disabled":_vm.disabled}},'v-btn',attrs,false),on),[_vm._t("default",function(){return [_c('v-icon',[_vm._v("mdi-dots-horizontal")])]})],2)]}}],null,true),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',{staticClass:"actionList",staticStyle:{"overflow":"hidden !important"},attrs:{"id":_vm.currentTable.id + '_menuContainer_' + _vm.additional.rowindex}},_vm._l((_vm.customActionButtonsArray),function(info,actionName){return _c('dynamicComponent',_vm._g({key:actionName,attrs:{"type":info.function,"result":{},"additional":{
          info: info,
          actionName: actionName,
          currentTable: _vm.currentTable,
          row: _vm.row,
          ..._vm.additional,
        }},on:{"close-menu":function($event){return _vm.closeMenu()},"show-warning":_vm.setupWarning}},_vm.$listeners))}),1)],1),_c('warning',{directives:[{name:"show",rawName:"v-show",value:(_vm.showWarning),expression:"showWarning"}],attrs:{"message":_vm.warningMessage}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }