<template>
  <v-row  cols="12" class="mt-2" :class="customClass">
    <div class="" v-if="table['selectable'] === true" id="selectAllContainer">
      <v-checkbox
          id="selectAll"
          label="Select All"
          color="primary"
          v-model="selectAll"
          onclick="selectAllVisible(this);"
          class="selectAllCheckBox padT10"></v-checkbox>
    </div>
    <div class="pa-2 mt-3 elevation-0" v-if="table['deletable'] === 'true'">
      <a @click="emitDeleteSelected()" class="black--text">
        <v-icon class="mr-1">mdi-delete</v-icon>
        Delete
      </a>
    </div>
    <div no-gutters class="d-flex additionalSelectableButtons mt-1">
      <dynamicComponent
          v-for="type in table['additionalSelectableButtons']"
          :key="type"
          :type="type"
          :result="result"
          :additional="{ selectedRows: selectedRows, table: table }"
          @show-warning="setupWarning"
          v-on="$listeners"></dynamicComponent>
      <AlmUpload v-if="typeof result!=='undefined' && typeof result.alm!=='undefined' && result.alm" :test-queue-ids="selectedRowIds" class="alm-upload-button"/>
    </div>
    <!-- <span class="restoreTrashCollectionSpan" v-show="table['showingTrash']">
      <a href="javascript:void(0);" onclick="restoreSelected(this);">
        <i class="fa fa-reply"></i> Restore selected
      </a>
      <a href="javascript:void(0);" onclick="wipeSelected(this);">
        <i title="Delete" class="fas fa-trash fs16 iconRed"></i> Delete selected
      </a>
    </span> -->
    <warning class="" :message="warningMessage" v-show="showWarning"></warning>
  </v-row>
</template>
<script>
import dynamicComponent from '@/commonComponents/dynamicComponent.vue';
import warning from '@/components/legacy/warning.vue';
import AlmUpload from '@/components/specific/AlmUpload';

export default {
  props: ['table', 'allSelected', 'result', 'selectedRows'],
  components: {
    AlmUpload,
    dynamicComponent,
    warning,
  },
  data: function () {
    return {
      warningMessage: 'Please select at least one row!',
      showWarning: false,
    };
  },
  computed: {
    customClass(){
      let vClass = "ml-9";
      if(typeof this.table['additionalSelectableButtons']!=='undefined' &&  this.table['additionalSelectableButtons'].length > 3 ){
        vClass = "ml-3";
      }
      return vClass;
    },
    selectAll: {
      get() {
        return this.allSelected;
      },
      set(val) {
        this.$emit('all-selected', val);
      },
    },
    selectedRowIds() {
      const selectedRowIds = [];
      for (const id of Object.keys(this.selectedRows)) {
        selectedRowIds.push(parseInt(id));
      }
      return selectedRowIds;
    },
  },
  methods: {
    setupWarning(value) {
      this.showWarning = value;
      setTimeout(() => {
        this.showWarning = false;
      }, 3000);
    },
    emitDeleteSelected() {
      this.$emit('delete-selected');
    },
  },
};
</script>

<style scoped>
.alm-upload-button {
  margin-top: 3px;
  margin-left: 10px;
}
</style>
